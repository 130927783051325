import React from 'react';
import Header from '../../../../shared/header';
import Footer from '../../../../shared/footer';
import About from '../../about';

function NissanSafc({categories}) {  
  return (
    <>
      <Header categories={categories}/>      
      <section className='single-block-wrapper section-padding'>
        <div className='container'>
          <div className='row'>        
           
              <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>
              <h2>Nissan 240SX Information - Apexi S-AFC Installation Guide</h2>
              This page provides detailed instructions for installing a Apex'i S-AFC in a 1991-1994 Nissan
              240SX.

              Apex'i Super Air Fuel Controller: The Apex SAFC allows you to control
              how much fuel will be delivered at certain rpms. This unit is mainly used
              for people who have changed either there MAF, injectors or cams. For best
              results this should be tuned using a dyno to insure proper settings. It provides many display
              modes such as RPM and Throttle % as well as having three brightness settings
              for optimal viewing.
              <div>Tools:</div>
              <ul>
                <li>Electrical Tape</li>
                <li>10mm Socket</li>
                <li>Phillips Screwdriver</li>
                <li>Wire Striper</li>
                <li>Tap Connectors (Optional)</li>
              </ul>
              Installation:
              <ul>
                <li>Disconnect Negative Battery Terminal</li>
              </ul>
              Remove 3 Screws on Passenger Side Kick Panel<br/>

              <a href="/images/240SX/safc/kickpanelscrew1.jpg">
                <img src="/images/240SX/safc/kickpanelscrew1_small.jpg" alt="Step 1" width="123" height="152" /></a><br/>
              <a href="/images/240SX/safc/kickpanelscrews2.jpg">
                <img src="/images/240SX/safc/kickpanelscrews2_small.jpg" alt="Step 2" width="217" height="287" /></a><br/>
                <br/>
              Remove Clear Cover That Snaps on Over The ECU Main Wiring Harness<br/>

              <a href="/images/240SX/safc/ecu_cover.jpg">
                <img src="/images/240SX/safc/ecu_cover_small.jpg" alt="Step 3" width="229" height="145" /></a><br/>
                <br/>
              Remove The ECU Wiring Harness From The ECU by Using The 10mm Socket<br/>
              <br/><br/>
              I used a tap to connect my wires to my ECU, the tap allowed me to install
              my afc without cutting any wires on the main harness for the ECU(see picture
              of tap below).
              <br/><br/>
              Installation Instructions:
              <ul>
                <li>Proper Wire Range (19-26 Gauge)</li>
                <li>Without cutting conductor, place unstriped run-wire inside run channel.</li>
                <li>Be sure tap wire is evenly cut. Push lightly on wire until it is as far inside the opening as possible.</li>
                <li>Use pliers to press down on connector cap until flush with the body if the connector.</li>
              </ul>
              <strong>NOTE: Wires should not be twisted after the connectors have been installed.</strong>

              <img src="/images/240SX/safc/tap.jpg" alt="Step 4" width="200" height="92" />

                <img src="/images/240SX/safc/install_misc.gif" alt="Step 5"  /><br/>
                <img src="/images/240SX/safc/ecu_diagram.jpg" alt="Step 6" />
              <table className='table'>
                <tbody>
                  <tr>
                    <td>
                      Apex'i SAFC Wiring Harness</td>
                    <td>
                      Function</td>
                    <td>
                      KA24DE &amp; S13 SR20DET ECU Wiring Harness</td>
                  </tr>
                  <tr>
                    <td>Red</td>
                    <td>Power</td>
                    <td>Black with White Stripe</td>
                  </tr>
                  <tr>
                    <td>Brown</td>
                    <td>Ground</td>
                    <td>Black</td>
                  </tr>
                  <tr>
                    <td>Black</td>
                    <td>Ground</td>
                    <td>Black</td>
                  </tr>
                  <tr>
                    <td>Green</td>
                    <td>Engine Tachometer (RPM)</td>
                    <td>Yellow with Red Stripe</td>
                  </tr>
                  <tr>
                    <td>Gray</td>
                    <td>Throttle Signal</td>
                    <td>White</td>
                  </tr>
                  <tr>
                    <td>Yellow</td>
                    <td>Air Flow / Pressure Output</td>
                    <td>White</td>
                  </tr>
                  <tr>
                    <td>White</td>
                    <td>Air Flow / Pressure Input</td>
                    <td>White</td>
                  </tr>
                  <tr>
                    <td>Blue</td>
                    <td>Not Used</td>
                    <td>Not Used</td>
                  </tr>
                  <tr>
                    <td>Pink</td>
                    <td>Not Used</td>
                    <td>Not Used</td>
                  </tr>
                  <tr>
                    <td>Orange</td>
                    <td>Not Used</td>
                    <td>Not Used</td>
                  </tr>
                </tbody>
              </table><br/>
              During Installation ECU Picture:<br/>

                <img src="/images/240SX/safc/install_half.gif" alt="Install 1"  />
                <br/>
              Completed ECU Picture:<br/>

                <img src="/images/240SX/safc/install_done.gif" alt="Install 2"  /><br/>
                <br/>
              Monitoring Choices:<br/>

                <img src="/images/240SX/safc/unit_choices.gif" alt="Install 3"  /><br/>

              Analog View of RPM and Throttle:<br/>

                <img src="/images/240SX/safc/unit_rev_thr.gif" alt="Install 4"  /><br/>

              Analog View of RPM and Throttle with Graphic Equalizer:<br/>

                <img src="/images/240SX/safc/unit_eq.gif" alt="Install 5"  /><br/>

              </div>
              <div className='col-md-3 d-none'>
                <div className='position-sticky'>
                  <About/>
                 
                </div>            
              </div>
            
            
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default NissanSafc;
