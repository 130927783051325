import React from 'react';
import Header from '../../../../shared/header';
import Footer from '../../../../shared/footer';
import About from '../../about';

function NissanEngineSR20({categories}) {  
  return (
    <>
      <Header categories={categories}/>      
      <section className='single-block-wrapper section-padding'>
        <div className='container'>
          <div className='row'>        
           
              <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>
                <h1>Engine Modifications:</h1>
                This Page Applies To The SR20DET Engine
                <br/>
                <br/>
                Apex'i Power FC &amp; Commander<br/>
                Apex'i Power Intake<br/>
                Z32 Nissan 300ZX 3" MAF<br/>
                GP Sports Type R 3 Core Aluminum Radiator &amp; Cooling Panel<br/>
                HKS SSQV Blow Off Valve<br/>
                Walbro 255lph High Pressure Fuel Pump<br/>
                Summit Oil Filter Relocation Kit<br/>
                Greddy Oil Pan<br/>
                Apex'i N1 Catback Exhaust - 3" Pipe , 4.5" Tip<br/>

                <img src="/images/240SX/engine/exhaust_after.jpg" alt="240SX Pictures"/><br/>

                Apex'i SAFC<br/>
                Perma-Cool Electric Fan and Thermostat<br/>
                Summit Battery Relocation Kit<br/>
                <br/>
                <br/>
                I got the complete swap which included:
                <br/>
                S13 SR20DET Redtop Engine<br/>
                5 Speed Transmission<br/>
                Drive Shaft<br/>
                Downpipe<br/>
                Stock Side Mount Intercooler and Piping<br/>
                Cam Angle Sensor<br/>
                Ignitor Chip<br/>
                Extra Throttle Body (One On Engine Was Broken)<br/>
                SR ECU and Harness<br/>
                Power Steering Pump (Used The One From The KA)<br/>
                AC Compressor (Took It Off Of The Engine)<br/>
                <br/>
                Yury and Jeff from SR Swap were very helpful and the two times I called them for assistance if they didn't
                answer the phone right then they called me back within 30 minutes and were able to
                help me troubleshoot my problems. The install went pretty good we didn't really struggle to much
                with any one thing. We started the swap Sunday night by removing the KA motor and finished it mid
                Friday afternoon.<br/><br/>

                Here is a view from the exhaust manifold side and my intake, I used my MAF adapter plate off of my
                Injen Intake from my KA24DE and then put the K &amp; N filter on as well. It seems to work pretty
                well and is much less restrictive than the stock air box the swap came with.<br/><br/>


                <img src="/images/240SX/engine/sr/sr_engine_exhaust.jpg" alt='Exhaust' /><br/>
                <br/>
                A front view<br/>
                <img src="/images/240SX/engine/sr/sr_engine_front.jpg" alt='Engine Front' /><br/>
                <br/>
                Another front view showing the pipe to the throttle body you can also see the fuel filter on the top left.
                <br/>
                <img src="/images/240SX/engine/sr/sr_engine_front_2.jpg"  alt='Engine Front'/><br/>
                <br/>
                Same as last view just zoomed out a little bit
                <br/>
                <img src="/images/240SX/engine/sr/sr_engine_front_3.jpg" alt='Engine Front' /><br/>
                <img src="/images/240SX/engine/sr/sr_engine_front_4.jpg" alt='Engine Front' /><br/>
                <br/>
                Intake Manifold View and also showing stock fuel rail and stock 370cc/min side feed injectors.
                <br/>
                <img src="/images/240SX/engine/sr/sr_engine_intake.jpg" alt='Engine Intake' /><br/>
                <br/>
                The Final Completed Picture<br/>
                <img src="/images/240SX/engine/sr/sr_engine_main.jpg" alt='Engine Main' /><br/>
                <br/>
                Another Intake Manifold View Picture<br/>
                <img src="/images/240SX/engine/sr/sr_engine_pass.jpg"  alt='Engine Side'/><br/>
                <br/>
                A shot of the work in progress.<br/>
                <img src="/images/240SX/engine/sr/sr_install_1.jpg" alt='Engine WIP' />
              </div>
              <div className='col-md-3 d-none'>
                <div className='position-sticky'>
                  <About/>
                 
                </div>            
              </div>
            
            
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default NissanEngineSR20;
