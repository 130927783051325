import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faGithub,faLinkedin, faPinterestP, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faRss } from '@fortawesome/free-solid-svg-icons';
class Footer extends Component {
  render(){
    return (
        <footer className='footer-section bg-grey d-print-none'>
            <div className='instagram-photo-section d-none'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h4 className='text-center'>Follow on Instagram</h4>
                        </div>
                    </div>
                    <div className='row no-gutters' id='instafeed'></div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 text-center'>
                        <ul className='list-inline footer-socials d-none'>
                            <li className='list-inline-item'><a href='/'><FontAwesomeIcon icon={faFacebook} className='text-dark me-2' />Facebook</a></li>
                            <li className='list-inline-item'><a href='/'><FontAwesomeIcon icon={faTwitter} className='text-dark me-2' />Twitter</a></li>
                            <li className='list-inline-item'><a href='/'><FontAwesomeIcon icon={faLinkedin} className='text-dark me-2' />LinkedIn</a></li>
                            <li className='list-inline-item'><a href='/'><FontAwesomeIcon icon={faPinterestP} className='text-dark me-2' />Pinterest</a></li>
                            <li className='list-inline-item'><a href='/'><FontAwesomeIcon icon={faGithub} className='text-dark me-2' />Github</a></li>
                            <li className='list-inline-item'><a href='/'><FontAwesomeIcon icon={faInstagram} className='text-dark me-2' />Instagram</a></li>
                            <li className='list-inline-item'><a href='/'><FontAwesomeIcon icon={faRss} className='text-dark me-2' />rss</a></li>
                        </ul>
                    </div>
                    <div className='col-md-12 text-center'>
                        <p className='copyright'>© Copyright {moment().format('YYYY')} - Tyler Merrick</p>
                    </div>
                </div>
            </div>
        </footer>
    );
  }
}

export default Footer;