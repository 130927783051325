import React, {useState, useEffect} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import './App.css';
import './scss/style.scss'
import { Routes, Route, useLocation } from "react-router-dom";
import Main from './Main';
import {Helmet} from "react-helmet";
import ViewByTag from './components/blog/viewByTag';
import ViewByCategory from './components/blog/viewByCategory';
import ViewContent from './components/blog/viewContent';
import Api from './services/api';
import Homepage from './components/home/homepage';
import ReactGA from 'react-ga4';
import NissanSafc from './components/blog/pages/nissan240sx/safc';
import NissanEngine from './components/blog/pages/nissan240sx/engine';
import NissanEngineSR20 from './components/blog/pages/nissan240sx/engineSR20';
import NissanGallery from './components/blog/pages/nissan240sx/gallery';
import NissanInterior from './components/blog/pages/nissan240sx/interior';
import ViewByQuery from './components/blog/viewByQuery';

function App({...props}) { 
  ReactGA.initialize('G-VZ8CKM2GHM');
  const [blogPosts, setBlogPosts] = useState([]);
  let hasFetchedBlogPostsRef = React.useRef(false);
  const [categories, setCategories] = useState([]);
  let hasFetchedCategoriesRef = React.useRef(false);
  const location= useLocation();
  useEffect(() => {
    async function fetchBlogPosts() {
      await Api.getPosts().then((d)=> {
        setBlogPosts(d);
        hasFetchedBlogPostsRef.current = true;
      });      
    }
    if (!hasFetchedBlogPostsRef.current){
      fetchBlogPosts();
    }
  }, [hasFetchedBlogPostsRef]); 
  
  useEffect(() => {
    async function fetchData() {
      await Api.getCategories().then((c)=> {
        setCategories(c);
        hasFetchedCategoriesRef.current = true;
      });      
    }
    if (!hasFetchedCategoriesRef.current){
      fetchData();
    }
  }, [hasFetchedCategoriesRef]); 
  useEffect(() => {
    ReactGA.send({hitType:'pageview', page:location.pathname + location.search});
  }, [location]);
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Tyler Merrick</title>
      </Helmet>
      <Routes>
        <Route path='/' element={<Homepage posts={blogPosts} categories={categories} { ...props}/>} />
        <Route path='/blog/' element={<Main posts={blogPosts} categories={categories} { ...props}/>} />
        <Route path='/blog/:year/:month/:day/:postName/*' element={<ViewContent posts={blogPosts} categories={categories} { ...props}/>}/>
        <Route path='/blog/tag/:tagName/*' element={<ViewByTag posts={blogPosts} categories={categories} { ...props}/>}/>
        <Route path='/blog/category/:categoryName/*' element={<ViewByCategory posts={blogPosts} categories={categories} { ...props}/>}/>
        <Route path='/blog/search/:query/*' element={<ViewByQuery posts={blogPosts} categories={categories} { ...props}/>}/>

        <Route path='/blog/automotive/nissan-240sx/nissan-240sx-interior-modifications/*' element={<NissanInterior categories={categories} {...props}/>}/>
        <Route path='/blog/automotive/nissan-240sx/apexi-s-afc-installation-guide/*' element={<NissanSafc categories={categories} { ...props}/>}/>
        <Route path='/blog/automotive/nissan-240sx/nissan-240sx-ka24de-engine-modifications/*' element={<NissanEngine categories={categories} {...props}/>}/>
        <Route path='/blog/automotive/nissan-240sx/nissan-240sx-sr20det-information/*' element={<NissanEngineSR20 categories={categories} {...props}/>}/>
        <Route path='/blog/automotive/nissan-240sx/nissan-240sx-picture-gallery/*' element={<NissanGallery categories={categories} {...props}/>}/>
      </Routes>
    </>
  );
}

export default App;
