import React, {useState, useEffect} from 'react';
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { useParams } from 'react-router-dom';
import Posts from './posts';

function ViewByTag({categories, posts}) {
  const {tagName } = useParams();
  const [filteredPosts, setFilteredPosts] = useState({});
  
  useEffect(() =>{
    const findPostsByTagName = () => {
      let items = posts.filter(post => {
        return post.tags?.filter(tag => {
          return tag.slug === tagName
        })?.length > 0
      });
      if (items){        
        setFilteredPosts(items);
      }
    };
    if (posts?.length > 0 && tagName){
      findPostsByTagName();
    } else {
      console.log('no tags');
    }
  },[posts, tagName]);
  
  return (
    <>
      <Header categories={categories}/>      
      <section className='section-padding'>
        <div className='container'>
          <div className='row'>
            { filteredPosts && tagName &&
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <Posts posts={filteredPosts} label={`Posts matching tag of ${tagName}`} />
            </div>
            }
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default ViewByTag;
