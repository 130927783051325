import React from 'react';
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import imgOahu from '../../assets/homepage/oahu.jpg';
import imgCharleston from '../../assets/homepage/charleston.jpg';
import imgPNW from '../../assets/homepage/pacific_northwest.jpg';
import imgChicago from '../../assets/homepage/chicago.jpg';
import imgFerrari from '../../assets/homepage/ferrari.jpg';
import imgDC from '../../assets/homepage/washington_dc.jpg';
import imgSouthHaven from '../../assets/homepage/south_haven.jpg';
import imgIndy from '../../assets/homepage/indianapolis.jpg';
import imgSmokys from '../../assets/homepage/smokys.jpg';
import { faCode,faCamera, faBurger, faChessBoard } from '@fortawesome/free-solid-svg-icons';
import Hobbies from './hobbies';
import Posts from '../blog/posts';

function Homepage({categories,posts}) {  
  return (
    <>
      { categories && 
      <Header categories={categories} />
      }
      <section className='mt-5'>
        <div className='container'>
          <div className='row'>
            { posts &&
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <Posts posts={posts.slice(0,3)} label='Recent Posts' />
            </div>
            }
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='row justify-content-center mt-5'>
                <div className='col-lg-12'>
                  <h3 className='pb-3 border-bottom'>Hobbies</h3>
                  <div className='row'>
                    <Hobbies icon={faCode} title='Programming' description='I have been writing code since the ice age, it has always fascinated me and I still get excited as I create new solutions.'/>
                    <Hobbies icon={faCamera} title='Photography' description='If I could just do one thing it would be to travel and take photographs of interesting places with my family.'/>
                    <Hobbies icon={faChessBoard} title='Board Games' description='I am very competitive and like to play a variety of board games to exercise my mind.'/>
                    <Hobbies icon={faBurger} title='Cooking' description='Everybody has to eat right? I enjoy trying new recipes and learning new techniques from books and videos.'/>
                  </div>
                  <h3 className='pb-3 mt-5 border-bottom'>Experiences</h3>

                  <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>Oahu</h4>
                        <img src={imgOahu} alt='' className='img-fluid'/>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>Charleston</h4>
                        <img src={imgCharleston} alt='' className='img-fluid'/>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>Pacific Northwest</h4>
                        <img src={imgPNW} alt='' className='img-fluid'/>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>Chicago</h4>
                        <img src={imgChicago} alt='' className='img-fluid'/>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>Ferrari Corse Clienti</h4>
                        <img src={imgFerrari} alt='' className='img-fluid'/>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>Washington D.C.</h4>
                        <img src={imgDC} alt='' className='img-fluid'/>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>South Haven</h4>
                        <img src={imgSouthHaven} alt='' className='img-fluid'/>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>Indianapolis</h4>
                        <img src={imgIndy} alt='' className='img-fluid'/>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                      <div className='about-widget mb-4 mb-lg-0'>
                        <h4 className='mt-3 text-center'>Smoky Mountains</h4>
                        <img src={imgSmokys} alt='' className='img-fluid'/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Homepage;
