import React,{useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars,faMagnifyingGlass,faXmark} from '@fortawesome/free-solid-svg-icons';
const Header =({categories}) => {
  const [searchText, setSearchText]=useState('');
  const navigate = useNavigate();
  const performSearch = (e) => {
    if(e.keyCode===13){
      navigate(`/blog/search/${searchText}`);
    }
  };
  const searchOnChange = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <>
      <header className='header-top bg-dark py-4 d-print-none'>
        <div className='container'>
          <nav className='navbar navbar-expand-lg navigation menu-white'>
            <a className='navbar-brand' href='/'>
              <h4 className='text-white'>Tyler Merrick</h4>
            </a>

            <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbar-collapse' aria-controls='navbar-collapse' aria-expanded='false' aria-label='Toggle navigation'>
              <FontAwesomeIcon icon={faBars} className='text-white' />
            </button>

            <div className='collapse navbar-collapse d-print-none' id='navbar-collapse'>
              <ul className='menu navbar-nav ms-auto float-start col-7'>
                <li className='nav-item dropdown'>
                  <Link to='/' className='nav-link dropdown-toggle'  id='navbarDropdown' role='button' aria-haspopup='true' aria-expanded='false'>Home</Link>
                </li>
                {categories && categories.length > 0 &&
                <li className='nav-item dropdown'>
                  <Link to='/blog/' className='nav-link dropdown-toggle' aria-haspopup='true' aria-expanded='false'>Blog</Link>
                  <div className='dropdown-menu' aria-labelledby='navbarDropdown2'>
                    {categories?.map((category,index)=>{ 
                      return <Link key={index} className='dropdown-item' to={`/blog/category/${category.slug}`}>{category.title}</Link>
                    })}
                  </div>
                </li>
                }
              </ul>
              <div className='col-sm-12 col-md-10 col-lg-4 text-lg-end float-end search ms-4'>
                <div className='row'>
                  <div className='col-1 pt-3'><FontAwesomeIcon className='text-white col float-start' icon={faMagnifyingGlass} /></div>
                  <div className='col'>
                    <input type='text' className='col form-control float-start' onKeyDown={(e)=>performSearch(e)} onChange={(e)=>searchOnChange(e)} placeholder='Search...' value={searchText}/>
                  </div>
                  <div className='col-1 pt-3'>
                    {searchText && 
                      <FontAwesomeIcon className='text-white col float-start' role='button' icon={faXmark} onClick={()=>setSearchText('')} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;