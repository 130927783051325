import React from 'react';
import Header from './shared/header';
import Footer from './shared/footer';
import Posts from './components/blog/posts';

function Main({categories, posts}) { 
  return (
    <>
      <Header categories={categories}/>      
      <section className='section-padding'>
        <div className='container'>
          <div className="row">
            { posts?.length > 0 &&
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <Posts posts={posts} label='Recent Posts' />
            </div>
            }            
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Main;
