import React, {useState, useEffect} from 'react';
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { useParams } from 'react-router-dom';
import Post from './post';
import About from './about';
import Archives from './archives';
import Advertisements from './advertisments';
import Api from '../../services/api';

function ViewContent({categories, posts}) {
  const {day, month, year, postName } = useParams();
  const [selectedPost, setSelectedPost] = useState({});
  let hasFetchedSelectedPostRef = React.useRef(false);
  useEffect(() =>{
    async function fetchBlogPost(id) {
      await Api.getPost(id).then((d)=> {
        setSelectedPost(d);
        hasFetchedSelectedPostRef.current = true;
      });      
    }
    const findPostByUrl = () => {
      let item = posts.find(post => {
        return post.slug === postName
      });
      if (item && !hasFetchedSelectedPostRef.current){
        fetchBlogPost(item.id);
      }
    };
    if (posts?.length && postName){
      findPostByUrl();
    }
  },[posts, day, month, year, postName]);
  
  return (
    <>
      <Header categories={categories}/>      
      <section className='single-block-wrapper section-padding'>
        <div className='container'>
          <div className='row'>
            { selectedPost && postName &&
            <>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <Post post={selectedPost} />
              </div>
              <div className='col-md-3 d-none'>
                <div className='position-sticky'>
                  <About/>
                  { selectedPost && selectedPost.asin?.length > 0 &&
                    <Advertisements post={selectedPost} />
                  }
                  {posts?.length > 0 &&
                    <Archives posts={posts} />
                  }
                </div>            
              </div>
            </>
            }
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default ViewContent;
