import React from 'react';

const About = () => {
    return (
        <div className='p-4 mb-3 bg-light rounded'>
            <h4 className='fst-italic'>About</h4>
            <p className='mb-0'>&nbsp;</p>
        </div>
    )
};
        
export default About;