import React from 'react';
import Header from '../../../../shared/header';
import Footer from '../../../../shared/footer';
import About from '../../about';

function NissanGallery({categories}) {  
  return (
    <>
      <Header categories={categories}/>      
      <section className='single-block-wrapper section-padding'>
        <div className='container'>
          <div className='row'>        
           
              <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>
                <h1>Picture Gallery:</h1>
                <img src="/images/240SX/misc_pics/drivers_side_front.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/drivers_side.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/drivers_side_rear.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/car_front_drv.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/rear.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/engine_front.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/turbo_maf_bov.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/engine_mods/z32_maf_power_intake/maf_intake.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/engine_mods/oil_filter/oil_filter.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/engine_mods/gp_sports_cooling/gp_sport_cooling.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/maf_hot_pipe.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/bov_top.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/wastegate_to_intake.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/stereo_afc.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/tweeters.jpg" alt="240SX Pictures" /><br/>
                <img src="/images/240SX/misc_pics/battery.jpg" alt="240SX Pictures" />
              </div>
              <div className='col-md-3 d-none'>
                <div className='position-sticky'>
                  <About/>
                 
                </div>            
              </div>
            
            
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default NissanGallery;
