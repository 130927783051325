import React from 'react';
import PostSummary from './postSummary';

const Posts = ({posts, label}) => {
    return (
        <>
            <div className='row'>
                <h3 className='pb-4 mb-4 fst-italic border-bottom'>
                    {label}
                </h3>
            </div>
            <div className='row'>
                { posts?.length > 0 &&
                    posts.map((p,i) => {
                        if (p.categories?.length > 0)  {
                            return <PostSummary post={p} key={i} />
                        }
                        return <></>
                    })
                }
            </div>
        </>
    )
};
        
export default Posts;