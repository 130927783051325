import React from 'react';
import Header from '../../../../shared/header';
import Footer from '../../../../shared/footer';
import About from '../../about';

function NissanInterior({categories}) {  
  return (
    <>
      <Header categories={categories}/>      
      <section className='single-block-wrapper section-padding'>
        <div className='container'>
          <div className='row'>        
           
              <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>
                <h1>Interior Modifications:</h1>
                Momo Tornado Steering Wheel<br/>
                <img src="/images/240SX/interior/steeringwheel.gif" alt='Steering wheel' /><br/><br/>
                <img src="/images/240SX/interior/steeringhandle.gif" alt='Steering wheel handle' /><br/><br/>
                Momo Shadow Shift Knob<br/>
                <img src="/images/240SX/interior/shiftboot3.jpg" alt='Shifter' /><br/><br/>
                Momo Super Turismo Pedals<br/>
                <img src="/images/240SX/interior/pedals4.jpg" alt='Pedals' /><br/><br/>
                Carbon Fiber Gauge Overlays<br/>
                <img src="/images/240SX/interior/gauges3.jpg" alt='Gauges' />
              </div>
              <div className='col-md-3 d-none'>
                <div className='position-sticky'>
                  <About/>
                 
                </div>            
              </div>
            
            
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default NissanInterior;
