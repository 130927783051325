import React from 'react';
const Advertisements = ({post}) => {
    return(           
        <div className='p-4'>            
            <h4 className='fst-italic'>Recommended Hardware</h4>
            <iframe title='amazon'
                sandbox='allow-popups allow-scripts allow-modals allow-forms allow-same-origin' 
                style={{width:'120px',height:'240px',display:'block' }}
                marginWidth='0' 
                marginHeight='0' 
                scrolling='no' 
                frameBorder='0' 
                src={`//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=oubahu-20&language=en_US&marketplace=amazon&region=US&placement=${post.asin}&asins=${post.asin}&linkId=9226b5037987857ddd6cd391f7ffd607&show_border=false&link_opens_in_new_window=true`}>
            </iframe>
        </div>        
    )
};
        
export default Advertisements;