import React from 'react';
import moment from 'moment';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
const Post = ({post}) => {
    return (
        <>
            {post && 
            <Helmet>
                <meta charSet='utf-8' />
                <title>Tyler Merrick - {post?.title ?? ''}</title>
                <meta name='description' content={post?.summary ?? ''} />
            </Helmet>
            }
            <div className='single-post'>
                <div className='post-header mb-5 text-center'>
                    <div className='meta-cat'>
                    {
                        post.categories?.map((category,index) => {
                            return <span key={index}>{(index > 0 && ', ')}<Link to ={`/blog/category/${category.slug}`} className='post-category font-extra text-color text-uppercase font-sm letter-spacing-1'>{category.title}</Link></span>
                        })
                    }
                    </div>
                    <h2 className='post-title mt-2'>
                        {post?.title}
                    </h2>
                    <div className='post-meta'>
                        <span className='text-uppercase font-sm letter-spacing-1'>{moment(post.date).format('MMMM Do, YYYY')}</span>
                    </div>
{/*                     <div className='post-featured-image mt-5'>
                        <img src='images/fashion/bg-banner.jpg' className='img-fluid w-100' alt='featured-image' />
                    </div> */}
                </div>
                <div className='post-body'>
                    <img className='img-fluid' src={`https://www.tylermerrick.com/static/blog/large/${post.thumbnail}`} alt={post.title} />
                    <div className='entry-content mt-4' dangerouslySetInnerHTML={{__html: post.content}}></div>                    
                    <div className='post-tags py-4 d-print-none'>
                    { post.tags?.length > 0 &&
                        post.tags.map((tag,index) => {
                            return <span key={index} className='badge bg-secondary me-2 fw-normal'>
                                <Link to ={`/blog/tag/${tag.slug}`} className='text-reset text-decoration-none'>{tag.title}</Link></span>
                        })                        
                    }
                    </div>
                    <div className='tags-share-box center-box d-flex text-center justify-content-between border-top border-bottom py-3 d-print-none'>
                        <div className='list-posts-share'>
                            <a target='_blank' rel='noreferrer nofollow' href={`https://www.facebook.com/sharer/sharer.php?u=${(window.location.href)}`}><FontAwesomeIcon icon={faFacebook} className='text-dark me-2' /></a>
                            <a target='_blank' rel='noreferrer nofollow' href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(post?.title)}`}><FontAwesomeIcon icon={faTwitter} className='text-dark me-2' /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
        
export default Post;