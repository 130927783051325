import React from 'react';
import Header from '../../../../shared/header';
import Footer from '../../../../shared/footer';
import About from '../../about';

function NissanEngine({categories}) {  
  return (
    <>
      <Header categories={categories}/>      
      <section className='single-block-wrapper section-padding'>
        <div className='container'>
          <div className='row'>        
           
              <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12'>
                <h1>Engine Modifications:</h1>
                This Page Applies To The KA24DE Engine<br/><br/>

                Injen Intake with Cold Air Extension<br/>
                Apex'i N1 Catback Exhaust - 3" Pipe , 4.5" Tip<br/>

                <img src="/images/240SX/engine/exhaust_after.jpg" alt='Exhaust' /><br/>
                <img src="/images/240SX/engine/ka/engine_driver.jpg" alt='KA Engine Driver' /><br/>
                <img src="/images/240SX/engine/ka/engine_front.jpg" alt='KA Engine Front' /><br/>
                <img src="/images/240SX/engine/ka/engine_pass.jpg" alt='KA Engine Passenger' /><br/>
                <img src="/images/240SX/engine/ka/ka_driver_angle.jpg" alt='KA Angle' /><br/>
                <img src="/images/240SX/engine/ka/ka_front.jpg" alt='KA Front 1' /><br/>
                <img src="/images/240SX/engine/ka/ka_front_2.jpg" alt='KA Front 2' /><br/>
                <img src="/images/240SX/engine/ka/ka_side.jpg" alt='KA Side' /><br/>
                <img src="/images/240SX/engine/ka/ka_out_intake_lower.jpg" alt='KA Intake' /><br/>
                <img src="/images/240SX/engine/ka/ka_out_harness_1.jpg" alt='KA Harness 1' /><br/>
                <img src="/images/240SX/engine/ka/ka_out_harness_2.jpg" alt='KA Harness 2' /><br/>
                <img src="/images/240SX/engine/ka/ka_out_exhaust.jpg" alt='KA Exhaust' /><br/>
                <img src="/images/240SX/engine/ka/ka_out_intake.jpg" alt='KA Intake' /><br/>
                <img src="/images/240SX/engine/ka/ka_out_front.jpg" alt='KA Exhaust Front' /><br/>
                <img src="/images/240SX/engine/ka/ka_out_exhaust_2.jpg" alt='KA Exhaust 2' />
              </div>
              <div className='col-md-3 d-none'>
                <div className='position-sticky'>
                  <About/>
                 
                </div>            
              </div>
            
            
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default NissanEngine;
