import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Hobbies({icon,title,description}) {  
  return (
    <div className='col-lg-3'>
      <h5 className='text-uppercase letter-spacing mb-4'><FontAwesomeIcon icon={icon} className='text-dark me-2' />{title}</h5>
      <p>{description}</p>
    </div>
  );
}

export default Hobbies;
